import React, { FC, useEffect } from 'react'
import {
  ThemeProvider,
  UnifiedTheme,
  Text,
  Grid,
  Box,
  Absolute,
  Flex,
  VStack,
  Token,
} from '@revolut/ui-kit'

import { GlobalStyle, textStyles } from './styles'
import { ROUTES, PUBLIC_ROUTES, WORKSPACES } from '@src/constants/routes'
import { Hero } from '@src/pages/Landing/components/Hero'
import { defaultTheme } from '@src/styles/theme'
import { FeatureBlock } from '@src/pages/Landing/components/FeatureBlock'

import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'

import { TabsBlock } from '@src/pages/Landing/components/TabsBlock'
import { FeatureItemsBlockCarousel } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsBlockCarousel'
import { FeatureItemsBlockProvider } from '@src/pages/Landing/components/FeatureItemsBlock/useFeatureItemsBlockContext'
import { FeatureItemsBlockWrapper } from '@src/pages/Landing/components/FeatureItemsBlock/FeatureItemsBlock'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { pathToUrl } from '@src/utils/router'
import { PlansBlockItem } from '@src/pages/Landing/components/PlansBlockItem'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { FooterLink } from '@src/pages/Landing/components/FooterLink'
import {
  HR_VIDEO_URL,
  PERFORMANCE_VIDEO_URL,
  RECRUITMENT_VIDEO_URL,
  HR_IMG,
  PERFORMANCE_IMG,
  RECRUITMENT_IMG,
  TESTIMONIAL_1_IMG,
  TESTIMONIAL_2_IMG,
  TESTIMONIAL_3_IMG,
} from '@src/pages/Landing/constants'
import { isWorkspacesEnabled } from '@src/utils'

interface LandingPageProps {
  isContactUsForm?: boolean
}

export const LandingPage: FC<LandingPageProps> = ({ isContactUsForm }) => {
  const carousel = {
    title: 'Customers can’t get enough',
    description:
      'Companies are seeing the results of Revolut People — here’s what our customers have to say.',
    button: {
      label: 'Sign up now',
      link: pathToUrl(ROUTES.SIGNUP.MAIN),
    },
    items: [
      {
        image: TESTIMONIAL_1_IMG,
      },
      {
        image: TESTIMONIAL_2_IMG,
      },
      {
        image: TESTIMONIAL_3_IMG,
      },
    ],
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isContactUsForm])

  const workspacesEnabled = isWorkspacesEnabled()

  return (
    <Box pb="s-16">
      <ThemeProvider
        theme={{ ...defaultTheme, textStyles } as unknown as UnifiedTheme}
        mode="dark"
      >
        <GlobalStyle isContactUsForm={isContactUsForm} />
        <Hero isContactUsForm={isContactUsForm} />

        <Absolute top={0} width="100%">
          <Flex
            maxWidth={{ all: '1000px', xxl: '1432px' }}
            width="100%"
            margin="auto"
            justifyContent="space-between"
            px="s-16"
            mt="s-16"
            alignItems="center"
          >
            <LogoAlt height="40" width="200" />
            {isContactUsForm ? (
              <Button
                variant="primary"
                use={InternalLink}
                to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                size="sm"
              >
                Sign up
              </Button>
            ) : (
              <>
                <Flex
                  gap="s-24"
                  alignItems="center"
                  display={{ all: 'none', md: 'flex' }}
                >
                  <InternalLink
                    to={
                      workspacesEnabled ? WORKSPACES.MAIN : pathToUrl(ROUTES.LOGIN.MAIN)
                    }
                  >
                    <Text variant="primary" fontWeight={500}>
                      Log in
                    </Text>
                  </InternalLink>

                  <Button
                    variant="primary"
                    use={InternalLink}
                    to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                    size="sm"
                  >
                    Sign up
                  </Button>
                </Flex>

                <Button
                  variant="secondary"
                  use={InternalLink}
                  to={workspacesEnabled ? WORKSPACES.MAIN : pathToUrl(ROUTES.LOGIN.MAIN)}
                  display={{ all: 'flex', md: 'none' }}
                  size="sm"
                >
                  Log in
                </Button>
              </>
            )}
          </Flex>
        </Absolute>

        <ContentBlockLayout>
          <VStack space="s-32">
            <VStack
              align={{ all: 'start', md: 'center' }}
              space={{ all: 's-8', lg: 's-24' }}
              width="100%"
            >
              <Text use="h2" variant="h2" textAlign={{ all: 'start', md: 'center' }}>
                Tap into your team’s potential
              </Text>
              <Text
                use="p"
                whiteSpace="pre-wrap"
                // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="subtitle1"
                textAlign={{ all: 'start', md: 'center' }}
              >
                {isContactUsForm ? (
                  <>
                    Power up your performance, and integrate all your people tools in one
                    place. Tested and perfected on our 10,000+ employees, this platform
                    supports every step of the employee journey. It’s a game-changer for
                    companies in any sector, anywhere. Ready to see how it can change
                    yours?
                  </>
                ) : (
                  <>
                    Unlock unparalleled productivity, and integrate all your people tools
                    in one place. Tested and perfected on our 10,000+ employees, this
                    platform supports every step of the employee journey. It’s a
                    game-changer for companies in any sector, anywhere. Ready to see how
                    it can change yours?
                  </>
                )}
              </Text>
            </VStack>

            <Grid
              use="ul"
              width="100%"
              columns={{ all: 1, md: 3 }}
              gap={{ all: 's-16', lg: 's-24' }}
            >
              <Box borderRadius={20} padding="s-24" bg="grouped-background">
                <Text variant="h3" use="h3" textAlign="center">
                  10,000+
                </Text>
                <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                  Revolut employees managed
                </Text>
              </Box>
              <Box borderRadius={20} padding="s-24" bg="grouped-background">
                <Text variant="h3" use="h3" textAlign="center">
                  60+
                </Text>
                <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                  Countries managed for teams across the globe
                </Text>
              </Box>
              <Box borderRadius={20} padding="s-24" bg="grouped-background">
                <Text variant="h3" use="h3" textAlign="center">
                  1.9M+
                </Text>
                <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                  applications processed per year
                </Text>
              </Box>
            </Grid>

            {isContactUsForm ? null : (
              <Flex gap="s-24" justifyContent={{ all: 'start', md: 'center' }}>
                <Button
                  variant="primary"
                  use={InternalLink}
                  to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                  size="sm"
                >
                  Sign up now
                </Button>
                <Button
                  variant="secondary"
                  use={InternalLink}
                  to={pathToUrl(ROUTES.CONTACT_US)}
                  size="sm"
                >
                  Contact sales
                </Button>
              </Flex>
            )}
          </VStack>
        </ContentBlockLayout>
        {isContactUsForm ? null : (
          <>
            <FeatureBlock
              title="Ace your HR"
              description="Transform the way you handle HR. We’ve used this platform to scale to a team of 10,000+, and manage employees and contractors across 55 countries, all from one place. It’s flexible and scalable — with automated, self-service tools that give your team time to get on with business."
              image={HR_IMG}
              video={HR_VIDEO_URL}
            />

            <FeatureBlock
              title="Power up your performance"
              description="Reach new heights by unlocking your teams’ true potential. Use tools to support development and productivity from day one. Stay aligned with KPIs and strategic roadmaps. Grow easily with a customisable organisation structure. Keep your people happy and productive."
              image={PERFORMANCE_IMG}
              video={PERFORMANCE_VIDEO_URL}
            />

            <FeatureBlock
              title={
                <>
                  Assemble your <Text whiteSpace="nowrap">A-team</Text>
                </>
              }
              description="Find the best people globally without the headache. Snag that standout candidate, hire with precision, scale a 100+ department with one tool. We’ve continuously developed it while processing 1.4m+ applications per year, and our skills and culture-based assessments help you raise the bar."
              image={RECRUITMENT_IMG}
              video={RECRUITMENT_VIDEO_URL}
            />

            <ContentBlockLayout>
              <Text use="h2" variant="h2" textAlign="center">
                All these features at your fingertips
              </Text>
            </ContentBlockLayout>

            <TabsBlock
              items={[
                {
                  id: 'hr',
                  title: 'HR',
                  blocks: [
                    {
                      id: 1,
                      title: 'Profiles',
                      description:
                        'Manage structured employee data in one easy-to-navigate place for total clarity and compliance.',
                      image: 'employees',
                    },
                    {
                      id: 2,
                      title: 'Time off',
                      description:
                        'Manage time off globally for all employees with leave policies, holidays, schedules, and approvals.',
                      image: 'time-off',
                    },
                    {
                      id: 3,
                      title: 'Documents',
                      description:
                        'Centralise, secure, and accelerate document management with automated bulk actions and integrated e-sign support.',
                      image: 'documents',
                    },
                    {
                      id: 4,
                      title: 'Payroll',
                      description:
                        'Manage and report your payroll. Easily resolve issues and track monthly changes for accuracy.',
                      image: 'payroll',
                    },
                    {
                      id: 5,
                      title: 'Lifecycle',
                      description:
                        'Handle the employee lifecycle from start to finish. Save time and simplify the process with automation and self-service tools.',
                      image: 'onboarding',
                    },
                    {
                      id: 6,
                      title: 'Communications',
                      description:
                        'Streamline your business updates. Instantly connect with your team through Slack, email, or Revolut People to maximise engagement.',
                      image: 'contracts',
                    },
                  ],
                },
                {
                  id: 'performance',
                  title: 'Performance',
                  blocks: [
                    {
                      id: 7,
                      title: 'Goals management',
                      description:
                        'Set transparent, metric-focused goals and KPIs. Define and manage roadmaps to achieve your goals. All connected to your higher-level company strategy.',
                      image: 'goals',
                    },
                    {
                      id: 8,
                      title: 'Skills & values',
                      description:
                        'Role-based skill frameworks and company values provide employees with clear expectations. Incorporate them into reviews to ensure more objective assessments.',
                      image: 'skills',
                    },
                    {
                      id: 9,
                      title: 'Reviews & feedback',
                      description:
                        'Build your dream team with 360 reviews. Identify top talent and manage underperformance to continuously raise the bar and promote employee growth.',
                      image: 'performance-reviews',
                    },
                    {
                      id: 10,
                      title: 'Probation & PIP',
                      description:
                        'Incorporate automated, dedicated, and compliant probation and performance improvement cycles, customisable to suit your needs.',
                      image: 'probation',
                    },
                    {
                      id: 11,
                      title: 'Performance profiles',
                      description:
                        'Track employee performance data from day one. Help identify trends and shape their career path with their reviews history, evolution of skills, and cultural fit.',
                      image: 'performance-improvement',
                    },
                    {
                      id: 12,
                      title: 'Organisation structure',
                      description:
                        'Add structure to your organisation. Add and align your teams and roles. Give people power to do more with transparency and clear ownership.',
                      image: 'pipelines',
                    },
                  ],
                },
                {
                  id: 'recruitment',
                  title: 'Recruitment',
                  blocks: [
                    {
                      id: 13,
                      title: 'Hiring planning',
                      description:
                        'Integrate headcount planning with recruitment. Oversee requisitions, open roles, and approvals. Get clarity to scale sustainably.',
                      image: 'hiring-process',
                    },
                    {
                      id: 14,
                      title: 'Job posting',
                      description:
                        'Create and promote postings on your own careers page to attract talent. Customise application forms and automatically filter candidates.',
                      image: 'job-postings',
                    },
                    {
                      id: 15,
                      title: 'Interview scheduling',
                      description:
                        'Integrate your favourite tools and calendar. Share booking links for candidates to select their own slots for efficiency and convenience.',
                      image: 'requisitions',
                    },
                    {
                      id: 16,
                      title: 'Candidate assessment',
                      description:
                        'Structured, skill-based hiring processes help you hire the best, without it taking forever. Objective assessments work to combat bias.',
                      image: 'candidates',
                    },
                    {
                      id: 17,
                      title: 'Pipelines',
                      description:
                        "Track your candidate's journey and share profiles with ease. Bulk candidate actions help recruitment teams spend less time on admin.",
                      image: 'hiring-pipelines',
                    },
                    {
                      id: 18,
                      title: 'Job offers',
                      description:
                        'Create and issue offers easily with job offers templates and e-sign integration. Always stay in control with embedded approval flows.',
                      image: 'offers',
                    },
                  ],
                },
              ]}
            />

            <FeatureItemsBlockWrapper>
              <FeatureItemsBlockProvider content={carousel} items={carousel.items}>
                <FeatureItemsBlockCarousel
                  title={carousel.title}
                  button={carousel.button}
                  description={carousel.description}
                  items={carousel.items}
                  style={{}}
                />
              </FeatureItemsBlockProvider>
            </FeatureItemsBlockWrapper>

            <ContentBlockLayout>
              <Text use="h4" variant="h4" textAlign="center">
                Choose your plan
              </Text>
            </ContentBlockLayout>
          </>
        )}

        <ContentBlockLayout>
          <Grid
            use="ul"
            columns={{ all: '1', md: '1fr 300px', lg: '1fr 350px' }}
            gap={{ all: 's-16', lg: 's-24' }}
          >
            <PlansBlockItem
              title="Standard"
              caption={
                <Text color={Token.color.blue}>
                  30 days free · £7.99 per active employee/month
                </Text>
              }
              description="Budget-friendly, business ready. Unlock your company’s full potential with our Standard plan. It includes all the features you need from HR, Performance, and Recruitment to perfect all things people."
              link={pathToUrl(ROUTES.SIGNUP.MAIN)}
            />
            <PlansBlockItem
              title="Enterprise"
              caption="Coming soon"
              description="All from Standard-plan + custom tailored solutions to supercharge your company."
              disabled
            />
          </Grid>
        </ContentBlockLayout>

        <ContentBlockLayout>
          <Text use="h4" variant="h4" style={{ textTransform: 'uppercase' }}>
            Get the one platform for all things people
          </Text>

          <Flex gap="s-24" mt="s-32">
            {isContactUsForm ? (
              <Button
                variant="secondary"
                onClick={() => {
                  document
                    .querySelector(`[data-form="contact-us"]`)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}
                size="sm"
              >
                Contact sales
              </Button>
            ) : (
              <>
                <Button
                  variant="primary"
                  use={InternalLink}
                  to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                  size="sm"
                >
                  Sign up now
                </Button>
                <Button
                  variant="secondary"
                  use={InternalLink}
                  to={pathToUrl(ROUTES.CONTACT_US)}
                  size="sm"
                >
                  Contact sales
                </Button>
              </>
            )}
          </Flex>
        </ContentBlockLayout>

        <ContentBlockLayout>
          <Box borderTop="1px solid" borderTopColor="white-10" pt="s-32">
            <Flex flexWrap="wrap" columnGap="s-32" rowGap="s-8" pb="s-32">
              <FooterLink
                href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)}
                target="_blank"
              >
                Website terms
              </FooterLink>
              <FooterLink href={PUBLIC_ROUTES.COMPLAINTS} target="_blank">
                Complaints
              </FooterLink>
              <FooterLink href={PUBLIC_ROUTES.PRIVACY_NOTICE} target="_blank">
                Privacy
              </FooterLink>
              <FooterLink
                href="https://www.revolut.com/legal/modern-slavery-statement#:~:text=We%20oppose%20all%20forms%20of,sex%20trafficking%2C%20and%20unpaid%20labour./"
                target="_blank"
              >
                UK Modern Slavery Policy
              </FooterLink>
            </Flex>
            <Text color="grey-tone-50" variant="small">
              © Revolut Ltd 2024
              <br />
              <br />
              If you would like to find out more about which Revolut entity you receive
              services from, or if you have any other questions, please reach out to us
              via the in-app chat in the Revolut app. Revolut Ltd (No. 08804411) is
              authorised by the Financial Conduct Authority under the Electronic Money
              Regulations 2011 (Firm Reference 900562). Registered address: 7 Westferry
              Circus, Canary Wharf, London, England, E14 4HD. Insurance related-products
              are arranged by Revolut Travel Ltd which is authorised by the Financial
              Conduct Authority to undertake insurance distribution activities (FCA No:
              780586) and by Revolut Ltd, an Appointed Representative of Revolut Travel
              Ltd in relation to insurance distribution activities. Trading and investment
              products are provided by Revolut Trading Ltd (No. 832790) is wholly owned
              subsidiary of Revolut Ltd and is an appointed representative of Resolution
              Compliance Ltd which is authorised and regulated by the Financial Conduct
              Authority.
              <br />
              <br />
              We are also registered with the Financial Conduct Authority to offer
              cryptocurrency services under the Money Laundering, Terrorist Financing and
              Transfer of Funds (Information on the Payer) Regulations 2017.
              <br />
              <br />
              Revolut's commodities service is not regulated by the FCA.
            </Text>
          </Box>
        </ContentBlockLayout>
      </ThemeProvider>
    </Box>
  )
}
